.content {
	background-color: #f7e1a1;
	display: flex;
	position: fixed;
	top: 50px;
	left: 200px;
	max-width: calc(100vw - 200px);
	right: 0;
	bottom: 0;
	overflow-x: auto;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}