.imagesContainer {
  height: 700px;
  width: 100%;
  overflow: visible;
}

.image {
  height: 610px;
  min-width: 280px;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 3px 3px 15px 5px #00000026;
  margin: 30px 0;
  pointer-events: none;
}
