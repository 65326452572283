.logoContainer {
	margin-top: 77px;
	width: 100%;
	display: flex;
	justify-content: center;
}

.logo {}

.errorMessage {
	width: 100%;
	text-align: center;
}