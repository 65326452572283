.imagesContainer {
  height: 700px;
  width: 100%;
  overflow: visible;
}

.image {
  height: auto;
  min-width: auto;
  max-width: 800px;
  border: 2px solid black;
  border-radius: 10px;
  box-shadow: 3px 3px 15px 5px #00000026;
  margin: 30px 0;
  pointer-events: none;
}

@media only screen and (max-width: 1000px) {
  .imagesContainer {
    height: auto;
  }

  .image {
    height: auto;
    min-width: 300px;
    max-width: 85vw;
  }
}
