.settlementsContainer {
	padding: 0 34px;
	margin: 0 34px;
}

.settlementsTitle {}

.settlements {
	width: 100%;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 1);
}

.settlement {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 0 34px;
}

.settlementLeft {
	display: flex;
	align-items: center;
	flex: 1
}

.settlementId {
	margin-right: 11px;
}

.settlementName {
	cursor: pointer;
}

.businessTypes {
	flex: 1
}

.businessTypeIcon {
	cursor: pointer;
	margin-right: 11px;
}


.settlementFunctions {
	justify-content: flex-end;
}

.edit {
	cursor: pointer;
}

.addSettlement {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
}