.businessesContainer {
	padding: 0 34px;
	margin: 0 34px;
}

.businessesTitle {
	text-transform: capitalize;
	text-align: center;
	width: 100%;
}

.businesses {
	width: 100%;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 1);
}

.business {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid black;
	padding: 0 34px;
}

.businessLeft {
	display: flex;
	align-items: center;
	width: 33%;
}

.businessId {
	margin-right: 11px;
}

.businessName {
	cursor: pointer;
	margin-right: 11px;
	width: 250px;
}


.businessIcon {
	cursor: pointer;
	margin-right: 11px;
}


.edit {
	cursor: pointer;
}

.addBusiness {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
}