.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  max-width: 800px;
  width: 85%;
  margin: 20px auto 0;
}

.developers {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  margin-top: 150px;
}

.developerIcon {
  width: 218px;
  height: 218px;
  border-radius: 200px;
  border: 2px solid black;
  overflow: hidden;
  box-shadow: 3px 3px 15px 5px #00000026;
}

.developerIconImage {
  width: 100%;
  height: 100%;
}

.developerDetails {
  width: 75%;
  max-width: 600px;
  border: 2px solid #000000;
  border-radius: 15px;
  padding: 120px 25px 50px;
  box-shadow: 3px 3px 15px 5px #00000026;
}

.username {
  text-transform: uppercase;
  width: 34%;
  border-top: 1px solid black;
  text-align: center;
  margin-top: 20px;
  padding: 20px 0 10px;
  font-weight: 600;
  line-height: 14px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.name {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 20px;
  padding: 10px 0 20px;
  min-width: 200px;
  font-size: 26px;
}

.description {
  flex-direction: column;
  margin-bottom: 57px;
}

.descriptionText {
  color: #666666;
  font-size: 18px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
}

.descriptionText:first-child {
  margin-bottom: 30px;
}

.icons {
  display: flex;
  max-width: 600px;
}

.icon {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.techIcon {
  height: 30px;
  width: 30px;
  color: black;
}

.techText {
  font-size: 14px;
  height: 14px;
  color: silver;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.icon:hover > .techIcon,
.icon:hover > .techText {
  color: #666666;
}
