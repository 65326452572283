body {
  position: relative;
  min-height: 100dvh;
  /* Ensures it covers the viewport height */
  z-index: 1;
  /* Make sure the content appears above the background */
  overflow: hidden;
  /* Prevents scrollbars caused by the pseudo-element */
}

/* Pseudo-element for the static background image */
.main::before {
  content: "";
  position: fixed;
  /* Make the background image static */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Ensure the background stays fixed */
  opacity: 0.3;
  /* Set the background image opacity */
  z-index: -1;
  /* Place the background image behind the content */
}

h1 {
  font-weight: bold;
  font-size: 34px;
  text-align: center;
}
