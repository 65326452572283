.field {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 500px;
}

.labelContainer {
  margin: 0 0 -16px 10px;
  padding: 0 7px;
  z-index: 4;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  height: 30px;
}

.label {
  border-radius: 5px;
  text-transform: capitalize;
  padding: 2px 5px;
  z-index: 4;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background-color: white;
  color: #666666;
}

.input,
.textArea {
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px;
  height: 30px;
}

.textArea {
  height: 150px;
}

.errors {
  font-family: "Montserrat", sans-serif;
}

.error {
  color: red;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 0 10px;
  background-color: white;
  padding: 4px 10px;
  border-radius: 5px;
}
