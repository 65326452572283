.sidebar {
	background-color: #28282b;
	left: 0;
	max-width: 200px;
	min-height: 100%;
	position: fixed;
	top: 50px;
	width: 100%;
	padding-top: 34px;
}

.menuItems {
	border-left: 1px solid white;
	list-style-type: none;
}

.menuItem {
	color: white;
	text-decoration: none;
	cursor: pointer;
	margin: 11px 0;
}