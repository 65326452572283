.button {
  margin: 34px auto;
  width: 100%;
  max-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 17px;
  padding: 7px 17px;
  color: white;
  border-color: white;
  border: 2px solid white;
  background-color: black;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.active {
  opacity: 0.6;
  color: black;
  border-color: black;
  border: 2px solid black;
  background-color: silver;
  pointer-events: none;
}

.button:disabled {
  opacity: 0.5;
}

.secondary {
  background-color: white;
  color: #666666;
  font-size: 16px;
  border: 1px solid black;
  text-transform: capitalize;
  width: auto;
  margin: 10px 0;
  max-height: 34px;
}

.link {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  border: none;
  text-transform: capitalize;
  width: auto;
  margin: 0;
  padding: 10px;
}

.link:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.iconContainer {
  margin: 0 0 0 10px;
}

.spinner {
  margin: 0 0 0 10px;
  height: 100%;
}
