.container {
	margin: 20px auto 0;
	width: calc(100% - 50px);
	max-width: 500px;
	padding: 0 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.buttonContainer {
	width: 130px;
}


.tab {
	border: none;
	text-align: center;
	width: 140px;
	padding: 5px;
	background-color: transparent;
	font-family: 'Montserrat', sans-serif;
	pointer-events: cursor;
}

.active {
	pointer-events: none;
	border-bottom: 1px solid black;
	color: black;
	opacity: 0.5;
}