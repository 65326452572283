/* Page container that scrolls and holds the app content */
.pageContainer {
  height: 100dvh;
  overflow: auto;
  position: relative; /* Needed for absolutely positioned elements */
}

/* Header stays at the top of the page */
.header {
  position: sticky;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  top: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 6;
  max-width: 1500px;
  box-shadow: 3px 3px 15px 5px #00000010;
}

/* Logo container starts centered but moves on scroll */
.logoContainer {
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

/* Logo image itself, additional styles can be added here */
.logo {
  width: 34px;
  margin: 20px 5px 20px 20px;
  height: auto;
  cursor: pointer;
}

.logoText {
  height: 40px;
  width: auto;
  max-width: 120px;
  height: auto;
  cursor: pointer;
}

.landingPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.landingImage {
  width: 140%;
  max-width: 800px;
  min-width: 500px;
  height: auto;
  padding-right: 20px;
  margin: 0 50%;
  cursor: pointer;
  display: flex;
}

.landingTextContainer {
  max-width: 800px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px auto;
  padding: 0 40px;
}

.landingText {
  font-size: 27px;
  color: black;
  margin: 0 0 10px 0;
}

/* This container holds the buttons and moves as the user scrolls */
.buttonContainer {
  height: 100px;
  display: flex;
  gap: 10px;
  transition: transform 0.3s ease-in-out;
  margin: 0 20px;
  align-items: center;
}

/* Container for the page content below the logo */
.appPageContainer {
  width: 75%;
  margin: 20px auto;
}

.work {
  margin-bottom: 400px !important;
}

/* Optional: additional app-specific styles */
.about {
  scroll-margin: 80px;
}

.contact {
  scroll-margin: 80px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 30px;
  border-top: 1px solid black;
}

.footerLogo {
  display: flex;
}

.footerText {
  font-size: 14px;
  line-height: 25px;
}
