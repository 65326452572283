.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoContainer {
  height: 50px;
}

.header {
  width: 85%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
}

.dropdown {
  border-radius: 5px;
  border: 1px solid black;
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 34px;
}

.dropdownControl {
  width: 160px;
  height: 34px;
  display: flex;
  border: none !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px !important;
  background: transparent !important;
}

.dropdownArrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownMenu {
  border: 1px solid black !important;
}

.titleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.titleWithLogo {
  display: flex;
  align-items: center;
}

.logo {
  width: 34px;
  margin-right: 20px;
  border-radius: 5px;
}

.descriptionContainer {
  width: 85%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.title {
  text-align: left;
  font-size: 24px;
  line-height: 24px;
}

.description {
  margin-bottom: 40px;
  color: #666666;
  white-space: pre-line;
}

.links {
  margin: 10px 0;
  width: 85%;
  display: flex;
  max-width: 800px;
  align-items: center;
}

.icon {
  margin-right: 20px;
}

.icon:hover {
  margin-right: 20px;
  color: #666666 !important;
}

.nowAvailable {
  text-align: center;
}

.comingSoon {
  margin: 20px auto;
  text-align: center;
  padding: 4px 20px;
  background-color: black;
  border-radius: 2px;
  /* color: #FCC953; */
  color: silver;
  opacity: 0.8;
  border-color: white;
  font-size: 13px;
  width: 200px;
  font-family: "Montserrat", sans-serif;
}
