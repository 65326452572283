.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  height: 70px;
  justify-content: space-between;
  left: 0;
  padding: 0;
  position: fixed;
  width: 100vw;
  z-index: 2;
  top: -10px;
  box-shadow: 3px 3px 15px 5px #00000026;
}

.headerLeft {
  width: 200px;
  display: flex;
  align-items: center;
  padding-left: 34px;
}

.headerRight {
  padding-right: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburgerContainer {
  margin-right: 11px;
}

.headerTitle {
  color: #28282b;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
}

.name {
  font-size: 14px;
  color: black;
  margin-right: 34px;
}
