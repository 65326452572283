.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
}

.form {
	margin-top: 20px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}