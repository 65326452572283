.contactMessage {
  padding: 34px;
}

.name {
  font-size: 23px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  margin: 30px 0;
}

.email {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 30px 0;
}

.message {
  height: 500px;
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin: 30px 0;
}

.responded {
}
